<template>
    <div class="right-contact">

        <a-popover placement="left">
            <template slot="content">
                <img class="kf-qr" src="@/assets/images/wx-kf.png" alt="">
            </template>
            <template slot="title">
                <span>微信客服（无须添加好友）</span>
            </template>
            <a-button class="lr-button">
                <img class="normal" ondragstart="return false" src="@/assets/images/fashion/r1.png">
                <img class="hover" ondragstart="return false" src="@/assets/images/fashion/r1w.png">
            </a-button>
        </a-popover>

        <a-popover placement="left">
            <template slot="content">
                <img class="kf-qr" src="@/assets/images/gzh.png" alt="">
            </template>
            <template slot="title">
                <span>微信扫一扫关注公众号</span>
            </template>
            <a-button class="lr-button">
                <img class="normal" ondragstart="return false" src="@/assets/images/fashion/r2.png">
                <img class="hover" ondragstart="return false" src="@/assets/images/fashion/r2w.png">
            </a-button>
        </a-popover>


        <a-popover placement="left">
            <template slot="content">
                <img class="kf-qr" src="@/assets/images/xcx.png" alt="">
            </template>
            <template slot="title">
                <span>微信扫一扫测试你的风格</span>
            </template>
            <a-button class="lr-button">
                <img class="normal" ondragstart="return false" src="@/assets/images/fashion/r3.png">
                <img class="hover" ondragstart="return false" src="@/assets/images/fashion/r3w.png">
            </a-button>
        </a-popover>


        <a @click="goTop" href="javascript:">
            <img class="normal" ondragstart="return false" src="@/assets/images/fashion/r4.png">
            <img class="hover" ondragstart="return false" src="@/assets/images/fashion/r4w.png">
        </a>


    </div>
</template>

<script>
    export default {
        name: "LRightContact",
        methods: {
            goTop() {

                const c = document.documentElement.scrollTop || document.body.scrollTop;
                if (c > 0) {
                    window.requestAnimationFrame(this.goTop);
                    window.scrollTo(0, c - c / 6);
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .right-contact {
        position: fixed;
        z-index: 9999;
        right: 20px;
        top: 50%;
        width: 60px;

        a, .lr-button {
            display: inline-block;
            text-align: center;
            line-height: 60px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: #F9F9F9;
            padding: 0;
            margin-bottom: 20px;
            border: 1px solid #ddd;

            img {
                max-width: 32px;
                display: inline;
            }

            .hover {
                display: none;
            }

            &:hover {
                background: #1F7762;
                border-color: #1F7762;

                .normal {
                    display: none;
                }

                .hover {
                    display: inline;
                }
            }
        }
    }

    .kf-qr {
        width: 200px;
    }
</style>