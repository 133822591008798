<template>
    <div class="footer">
        <div class="footer-logo">
            <img src="@/assets/images/common/flogo.png" alt="">
        </div>
        <div class="footer-contact">
            <a-row class="footer-contact-left">
                <a-col span="16">
                    <div class="footer-sub-title">联系我们</div>
                    <a-row type="flex" justify="space-around" align="middle">
                        <a-col class="contact-row" span="12">
                            <div><img src="@/assets/images/common/zj.png" alt="座机"></div>
                            <span>028-88886666</span>
                        </a-col>
                        <a-col class="contact-row" span="12">
                            <div><img src="@/assets/images/common/yx.png" alt="邮箱"></div>
                            <span>838328223@qq.com</span>
                        </a-col>
                        <a-col class="contact-row" span="12">
                            <div><img src="@/assets/images/common/sj.png" alt="手机"></div>
                            <span>177-2829-8382</span>
                        </a-col>
                        <a-col class="contact-row" span="12">
                            <div><img src="@/assets/images/common/dz.png" alt="地址"></div>
                            <span>四川省成都市天府二街雄川金融中心一期</span>
                        </a-col>
                    </a-row>
                </a-col>
                <a-col span="8">
                    <a-row>
                        <a-col span="6">
                            <div class="footer-sub-title">在线客服</div>
                            <div class="wx-img">
                                <img src="@/assets/images/wx-kf.png" alt="">
                            </div>
                        </a-col>
                        <a-col span="6" offset="2">
                            <div class="footer-sub-title">微信公众号</div>
                            <div class="wx-img">
                                <img src="@/assets/images/gzh.png" alt="">
                            </div>
                        </a-col>
                    </a-row>
                </a-col>

            </a-row>
        </div>

        <div class="footer-links-container">
            <div class="footer-sub-title">友情链接</div>
            <a-row class="footer-links" type="flex" justify="start" align="middle">
                <a-col span="4">腾讯云</a-col>
                <a-col span="4">百度云</a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LFooter"
    }
</script>

<style lang="less" scoped>
    .footer {
        padding: 65px 360px 0;
        background: #0D392E;
        text-align: left;
        color: rgba(249, 249, 249, 1);
        font-size: 16px;
        line-height: 22px;
        overflow: hidden;
    }

    .footer-logo {
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid #F9F9F9;

        img {
            height: 24px;
        }


    }

    .footer-sub-title {
        font-size: 18px;
        font-weight: 400;
        color: #F9F9F9;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .footer-contact-left p {
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 0;
    }

    .wx-img {
        display: inline-block;
        width: 88px;
        height: 88px;
        background: #F9F9F9;
        margin-right: 40px;
        text-align: center;
        img{
            width: 100%;
            margin-bottom: 5px;
        }
    }

    .footer-contact {
        padding-bottom: 25px;
        margin-bottom: 15px;
        border-bottom: 1px solid #F9F9F9;
    }

    .footer-links-container {
        text-align: left;
    }

    .footer-links {
        padding: 10px 0 30px 0;
    }

    .contact-row {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
            display: inline-block;
            width: 30px;
            text-align: left;
            img{
                max-height: 100%;
                max-width: 100%;
            }
        }
    }

</style>